import React from "react";
import "../styles/hazProcedures.css";
export const HazProceduresPage = () => {
  const carriers = [
    {
      name: "MSC",
      info: "Acceptible, follow booking procedure",
      instructions:
        "https://drive.google.com/file/d/1Thmh2g2lvp1iv_Cqts5n7WQl9T0z9da8/view?usp=sharing",
    },
    { name: "YANG MING", info: "Prohibited to ship used lithium batteries" },
    { name: "ONELINE", info: "Prohibited to ship used lithium batteries" },
    { name: "EVERGREEN", info: "Prohibited to ship used lithium batteries" },
    { name: "OOCL", info: "Prohibited to ship used lithium batteries" },
    {
      name: "ANL & CMA",
      info: "Acceptible, difficult to obtain booking",
      instructions:
        "https://docs.google.com/document/d/1HIyFqK4DrcJEdPcqOSKNG7uecFeFK82I4jQEBxR2ONA/edit?usp=sharing",
    },

    {
      name: "PDF",
      info: "Hazardous Procedures PDF Form",
      instructions:
        "https://drive.google.com/file/d/1bMkqGf38UkINqTzP7W7TPnUs5ivEQ1Nz/view?usp=sharing",
    },
  ];

  return (
    <div className="carrierContainer">
      <h1>Hazardous Vehicle Procedures</h1>
      <h2>NOTE: Download Files to your PC to edit them.</h2>
      <table class="hazVehicleTable">
        <thead>
          <tr>
            <th>Carrier Name</th>
            <th>Information</th>
            <th>Instructions</th>
          </tr>
        </thead>
        <tbody>
          {carriers.map((carrier, index) => (
            <tr key={index}>
              <td>
                <strong>{carrier.name}</strong>
              </td>
              <td>{carrier.info}</td>
              <td>
                {carrier.instructions && (
                  <a
                    href={carrier.instructions}
                    target="_blank"
                    className="hazInstructions"
                    rel="noopener noreferrer"
                  >
                    Click here to open instructions
                  </a>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
